<template>
  <multiselect
    v-if="visible"
    v-model="selected"
    :placeholder="placeholder"
    :internal-search="false"
    :options="results"
    :searchable="true"
    :loading="isLoading"
    :options-limit="20"
    :max-height="600"
    :custom-label="customLabel"
    selected-label=""
    select-label=""
    @search-change="asyncFindApi"
    @select="onSelect"
    @open="hideOthers"
    @close="showOthers"
  >
    <template
      slot="option"
      slot-scope="props"
    >
      <div class="row h-100">
        <div class="col-lg-8 col-md-8 my-auto">
          <span class="option__title">{{ props.option.name }}</span>
        </div>
        <div class="col-lg-4 col-md-4">
          <img
            class="option__image"
            height="100"
            :src="props.option.snapshot_url"
            :alt="props.option.name"
          >
        </div>
      </div>
    </template>
  </multiselect>
</template>

<script>
import { sync } from 'vuex-pathify';

import multisearch from '@/mixins/multisearch';
import debounce from '@/mixins/debounce';

export default {
  mixins: [
    multisearch, //
    debounce, //
  ],

  data() {
    return {
      type: 'skin',
      placeholder: 'Skin Search',
    };
  },

  computed: {
    ...sync([
      'webamp/skin', //
    ]),
  },

  mounted() {
    this.selected = {
      name: this.skin.name,
    };
  },

  methods: {
    onSelect(option) {
      // console.log('onSelect', option);
      const skin = {
        name: option.name,
        // url: option.local_path.length > 0 ? option.local_path : option.s3_url,
        url: option.s3_url,
      };
      this.$store.set('webamp/skin', skin);
      Bus.$emit('skin:changed', skin);
    },

    customLabel(option) {
      // console.log('customLabel', option);
      return $('<div/>').html(option.name).text();
    },

    asyncFindApi(query) {
      this.debounce(() => {
        // console.log('asyncFindApi.query', query);
        this.results = [];
        this.isLoading = true;

        this.$http.get('/api/v1/search/skins', { params: { query } }).then((res) => {
          // console.log('res.data', res.data);
          this.results = res.data;
          this.isLoading = false;
        });
      }, 200);
    },
  },
};
</script>
